import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { coreSelectors } from '../../../Core/Store/Selectors';
import { avaOverviewSelectors } from '../../Store/Selectors';
import { createScope } from '@elixir/fx';
import { ElxScopedTableContainer } from '@elixir/fx/lib/components/Container/Variations';
import { ElxLink } from '@elixir/components';
import { IColumn, SelectionMode } from '@fluentui/react';
import { fetchMyCasesAction } from '../../Store/Actions';
import { CaseDataDetails, avaContainers } from '../../../Core/core.data';
import { renderAutoRefreshToggleSubActions } from '../../../PageHeader/Components/AutoRefreshToggle/AutoRefreshToggle';
import { 
    getCasesTableActions, 
    getTeamFilter, 
    getChannelFilter, 
    getStatusFilter, 
    getSeverityFilter, 
    getOfferingFilter,
    getCaseStatus,
    convertOffering,
    CASE_REFRESH_WAIT_TIME,
    getLifeAndSafetyString,
} from '../../../../Common/Case.utility';
import {
    getTruncatedTime,
    renderEmptyView,  
} from '../../../../Common/Core.utility';
import { AvaErrorBoundary } from '../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary';
import { mainTabFix } from '../../../../Common/TabIndexFix';

/**
 * My cases page table.
 */
export interface MyCasesTable {
    caseNumber: JSX.Element,
    teamChannel: string,
    caseTitle: string,
    created: string,
    status: string,
    lifeAndSafety: string,
    severity: string,
    offering: string,
    teamsUrl: string,
    raveUrl?:string,
    serviceDeskUrl?: string,
    ascUrl?: string,
    dynamicsUrl?: string
}

/**
 * Columns for the my cases table
 */
const columns: IColumn[] = [
    {
        key: 'column_CaseNumber',
        name: 'Case Number',
        fieldName: 'caseNumber',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
    },
    {
        key: 'column_TeamChannel',
        name: 'Team/Channel',
        fieldName: 'teamChannel',
        minWidth: 100,
        maxWidth: 180,
        isResizable: true,
    },
    {
        key: 'column_CaseTitle',
        name: 'Case Title',
        fieldName: 'caseTitle',
        minWidth: 100,
        maxWidth: 400,
        isResizable: true,
    },
    {
        key: 'column_Created',
        name: 'Created (UTC)',
        fieldName: 'created',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true
    },
    {
        key: 'column_SupportEngineer',
        name: 'Support Engineer',
        fieldName: 'supportEngineer',
        minWidth: 120,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'column_Status',
        name: 'Status',
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true
    },
    {
        key: 'column_TimeLastChecked',
        name: 'Last Checked',
        fieldName: 'timeLastChecked',
        minWidth: 120,
        maxWidth: 160,
        isResizable: true,
    },
    {
        key: 'column_LifeAndSafety',
        name: 'Life & Safety',
        fieldName: 'lifeAndSafety',
        minWidth: 100,
        maxWidth: 160,
        isResizable: true
    },
    {
        key: 'column_Severity',
        name: 'Severity',
        fieldName: 'severity',
        minWidth: 60,
        maxWidth: 100,
        isResizable: true
    },
    {
        key: 'column_Offering',
        name: 'Offering',
        fieldName: 'offering',
        minWidth: 120,
        maxWidth: 220,
        isResizable: true
    },
];

const emptyColumns: IColumn[] = []

const scope = createScope(avaContainers.AVA_MY_CASES);

/**
 * This container is the My Cases on the portal
 */
export const MyCases = (): JSX.Element => {
    const dispatch = useDispatch();

    const isPageLoading = useSelector(coreSelectors.getPageLoading);
    
    const myCasesSelector = useSelector(avaOverviewSelectors.getMyCases);
    const myCases = myCasesSelector || [];
    const items = getMyCasesTable(myCases);
    const autoRefreshEnabled = useSelector(coreSelectors.getAutoRefreshEnabled) || false;

    const [visibleHeader, setVisibleHeader] = React.useState(true);

    // Fetch my cases for the first time.
    React.useEffect(() => {
        setVisibleHeader(true);
        dispatch(fetchMyCasesAction(scope));
        mainTabFix();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Auto-refresh every 30secs to fetch my cases 
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!isPageLoading && autoRefreshEnabled) {
                setVisibleHeader(true);
                dispatch(fetchMyCasesAction(scope));
            }
        }, CASE_REFRESH_WAIT_TIME);

        return () => clearInterval(interval)
    });

    return (
        <AvaErrorBoundary>
        <ElxScopedTableContainer
          containerProps={{
            headerText: 'My cases',
            subHeaderText: 'Cases you have created or checked as an expert from the last 30 days',
            isLoading: isPageLoading,
            subActions: renderAutoRefreshToggleSubActions(),
          }}
          tableProps={{
            columns: visibleHeader ? columns : emptyColumns,
            items: items.sort((a, b) => (getTime(a.created) - getTime(b.created))),
            stickyHeader: true,
            actions: getCasesTableActions(),
            actionsName: visibleHeader ? '' : ' ',
            selectionMode: items.length > 0 ? SelectionMode.single : SelectionMode.none,
            ariaLabelForGrid: "Cases from the last 30 days"
          }}
          searchBoxProps={{
            filters:[
              getTeamFilter(myCases), 
              getChannelFilter(myCases), 
              getStatusFilter(myCases), 
              getSeverityFilter(myCases), 
              getOfferingFilter(myCases),
            ],
            maxInlineFilters: 5
          }}
          onRenderEmpty={() => {
                if(!isPageLoading) { setVisibleHeader(false); }
                return renderEmptyView("No my cases found.")
            }
          } 
            
        >
        </ElxScopedTableContainer>
        </AvaErrorBoundary>
    );
}

/**
 * Get time
 * @param dateValue 
 */
function getTime(dateValue: string) {
    const date = new Date(dateValue);
    return date != null ? date.getTime() : 0;
}

/**
 * Method to map my cases data to my cases table.
 * @param myCases My cases data.
 */
function getMyCasesTable(myCases: CaseDataDetails[]): MyCasesTable[] {
    let result: MyCasesTable[] = [];
    if (myCases) {
        result = myCases
            .map(c =>({
                caseNumber: <ElxLink href={c.linkToCase} target='_blank'>{c.caseNumber}</ElxLink>,
                teamChannel: c.team.name + '/' + c.channelName,
                caseTitle: c.title,
                created: getTruncatedTime(c.timeCreated),
                supportEngineer: c.supportEngineer.name,
                status: getCaseStatus(c),
                timeLastChecked: getTruncatedTime(c.timeLastChecked || ''),
                lifeAndSafety: getLifeAndSafetyString(c),
                severity: c.severity,
                offering: convertOffering(c.offering),
                teamsUrl: c.linkToCase,
                raveUrl: c.toolsUrls['rave'],
                serviceDeskUrl: c.toolsUrls['sd'],
                ascUrl: c.toolsUrls['asc'],
                dynamicsUrl: c.toolsUrls['dfM']
            }));
    }
    return result;
}
