import * as React from "react";
import { createScope, getFrameworkInstance, ElxScopedContainer } from "@elixir/fx";
import { IElxContainerProps, IContainerAction } from '@elixir/components';
import { PageHeader } from "../../../PageHeader/Containers/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { coreSelectors } from "../../../Core/Store/Selectors";
import { RoleLabel } from "../../../PageHeader/Components/RoleLabel/RoleLabel";
import { UserDetails, avaContainers } from "../../../Core/core.data";
import { ScheduleNotificationsTile } from "./ScheduleNotificationsTile/ScheduleNotificationsTile";
import { AutoArchiveTile } from "./AutoArchiveTile/AutoArchiveTile";
import { TeamPermissionsTile } from "./TeamPermissionsTile/TeamPermissionsTile";
import { fetchTeamAction } from "../../../Core/Store/Actions";
import { teamSettingsSelectors } from "../../Store/Selectors";
import { GeneralSettingsPanel } from "./GeneralSettingsPanel/GeneralSettingsPanel";
import { PreviewToggle } from "../../../PageHeader/Components/PreviewToggle/PreviewToggle";
import {
  IGeneralSettingsPanelProps,
  AutoArchiveDetails,
  NotificationDelayDetails,
  DEFAULT_CASE_NOTIFICATION_TIMES,
} from "../../Models/teamsettings.data";
import { actions } from "../../Store/Slice";
import "./General.scss";
import { NotificationIntervalsTile } from "./NotificationIntervalsTile/NotificationIntervalsTile";
import { fetchGeneralSettingsAction } from "../../Store/Actions";
import { AvaErrorBoundary } from "../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary";
import { mainTabFix } from "../../../../Common/TabIndexFix";

export interface IGeneralTileProps {
  onEdit(tabIndex: number): void;
}

const generalScope = createScope(avaContainers.AVA_GENERAL_SETTINGS);

export const General = (): JSX.Element => {
  const dispatch = useDispatch();

  const [panelProps, setPanelProps] = React.useState(
    {} as IGeneralSettingsPanelProps
  );

  const isPageLoading = useSelector(coreSelectors.getPageLoading);
  const user = useSelector(coreSelectors.getCurrentUser);
  const activeTeam = useSelector(coreSelectors.getActiveTeam);

  const rolesCollection = useSelector(teamSettingsSelectors.getTeamRoles);
  const settings = useSelector(teamSettingsSelectors.getGeneralSettings);

  const autoArchiveSelector = useSelector(
    teamSettingsSelectors.getAutoArchiveDetails
  );
  const [autoArchiveDetails, setAutoArchiveDetails] =
    React.useState<AutoArchiveDetails>({ isEnabled: true, hours: 96 });

  const notificationDelaySelector = useSelector(
    teamSettingsSelectors.getNotificationDelayDetails
  );
  const [notificationDelayDetails, setNotificationDelayDetails] =
    React.useState<NotificationDelayDetails>({ isEnabled: false, minutes: 0 });

  React.useEffect(() => {
    mainTabFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (autoArchiveSelector) {
      setAutoArchiveDetails({
        isEnabled: autoArchiveSelector.isEnabled,
        hours: autoArchiveSelector.hours,
      });
    }
  }, [autoArchiveSelector]);

  React.useEffect(() => {
    if (notificationDelaySelector) {
      setNotificationDelayDetails({
        isEnabled: notificationDelaySelector.isEnabled,
        minutes: notificationDelaySelector.minutes,
      });
    }
  }, [notificationDelaySelector]);

  const roles = rolesCollection.roleDetails || [];
  const permissions = settings?.permissions || [];
  const notifyChannels = settings?.scheduleNotificationDetails || [];
  const notificationIntervals =
    settings?.teamSettingsCaseNotificationTimes ||
    DEFAULT_CASE_NOTIFICATION_TIMES;
  const activeTeamId = activeTeam?.teamsTeamId;

  React.useEffect(() => {
    if (!isPageLoading && activeTeamId) {
      dispatch(
        fetchTeamAction(activeTeamId, generalScope, "settings,roles,channels")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeamId, dispatch]);

  const showGeneralPanel = useSelector(
    teamSettingsSelectors.getGeneralSettingsPanel
  );
  React.useEffect(() => {
    if (activeTeamId) {
      dispatch(fetchGeneralSettingsAction(activeTeamId, generalScope));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeamId, showGeneralPanel, dispatch]);

  const onEditCallback = (tabIndex: number) => {
    setPanelProps({
      settings: settings,
      selectedTabIndex: tabIndex,
    });

    getFrameworkInstance().store.dispatch(actions.showGeneralPanel(true));
  };

  const actionBtnProps: IContainerAction[] = [
    {
      key: "1",
      text: "Edit team settings",
      onClick: () => {
        onEditCallback(0);
      },
    },
    {
      key: "2",
      text: "View documentation",
      href: "https://azsupportdocs.azurewebsites.net/ava/articles/GettingStarted.html#onboarding-steps",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  const displayError = useSelector(coreSelectors.getDisplayError);

  return (
    <ElxScopedContainer
      headerText=""
      subHeaderText="View and manage team settings"
      onRenderHeader={(props: IElxContainerProps) => (
        <PageHeader
          containerScope={generalScope}
          include="settings,roles,channels"
          actions={actionBtnProps}
        />
      )}
      subActions={renderSubActions(user)}
      actions={displayError ? undefined : actionBtnProps}
      scope={generalScope}
      className="subActions"
    >
      <AvaErrorBoundary>
        <ElxScopedContainer className="hideHeader" isLoading={isPageLoading}>
          <div className="ms-Grid ms-sm12" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6">
                <NotificationIntervalsTile
                  notificationIntervals={notificationIntervals}
                  notificationDelayDetails={notificationDelayDetails}
                  onEdit={onEditCallback}
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col">
                <ScheduleNotificationsTile
                  notifyChannels={notifyChannels.filter(
                    (x) => x.areNotificationsEnabled
                  )}
                  onEdit={onEditCallback}
                />
              </div>
              <div className="ms-Grid-col">
                <AutoArchiveTile
                  settings={autoArchiveDetails}
                  onEdit={onEditCallback}
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm8">
                <TeamPermissionsTile
                  roles={roles}
                  permissions={permissions}
                  onEdit={onEditCallback}
                />
              </div>
            </div>
          </div>
          <GeneralSettingsPanel {...panelProps} />
        </ElxScopedContainer>
      </AvaErrorBoundary>
    </ElxScopedContainer>
  );
};

function renderSubActions(user?: UserDetails): JSX.Element[] {
  const subActions: JSX.Element[] = [];

  if (user?.isAdmin) {
    subActions.push(<PreviewToggle user={user} />);
  }

  subActions.push(<RoleLabel user={user} />);

  return subActions;
}
