import { IElxConfig } from "@elixir/fx";
import { LogLevel, LoginType, CacheLocation, InteractionType } from "@elixir/auth";
import type { IAuthenticationConfig } from "@elixir/auth";

interface IElixirConfig extends IElxConfig {
  buildNumber: string;
  apiUrl: string;
  serviceId: string;
  avaSupportEmail: string;
}

const authConfig: IAuthenticationConfig = {
  authentication: 'aad2',
  interactionType: InteractionType.Redirect,
  tokenRefreshUrl: "/auth.html",
  restrictExternalUsers: true,
  tenantId: process.env.REACT_APP_AUTH_TENANT_ID as string,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  authorityURL: "https://login.microsoftonline.com/",
  tokenCacheLocation: "localStorage" as CacheLocation,
  redirectUri: process.env.REACT_APP_BASE_URL,
  scopes: [`${process.env.REACT_APP_SERVICE_SCOPE}`],
  graphScopes: ["User.ReadBasic.All"],
};

const defaultConfig: IElixirConfig = {
  env: process.env.REACT_APP_ENV as string,
  buildEnv: process.env.REACT_APP_ENV ?? "",
  buildNumber: process.env.REACT_APP_BUILD_NUMBER ?? "0.0.0.0",

  // Application runtime config
  applicationTitle: "Ava",
  applicationIconName: "",
  baseUrl: process.env.REACT_APP_BASE_URL ?? "",
  apiUrl: process.env.REACT_APP_API_URL ?? "",
  serviceId: process.env.REACT_APP_SERVICE_ID ?? "",
  personaCacheSize: 50,
  debug: process.env.REACT_APP_CONSOLE_LOGS === "true",

  // Support contact
  avaSupportEmail: "avasupp@microsoft.com",

  // Authentication config
  authConfig,
};

const getConfig = (env?: string): IElixirConfig => {
  defaultConfig.authConfig = {
    ...defaultConfig.authConfig,
    authLogLevel: LogLevel.Info,
  };

  if (process.env.REACT_APP_SERVICE_SCOPE) {
    defaultConfig.authConfig?.scopes?.push(process.env.REACT_APP_SERVICE_SCOPE);
  }

  return defaultConfig;
};

const config: IElixirConfig = getConfig(process.env.REACT_APP_BUILD_ENV);

/**
 * Returns the base url for service.
 */
export function getApiUrl(): string {
  return config.apiUrl;
}

export function getCurrentConfig(): IElixirConfig {
  return config;
}

export default config;
