import React from "react";
import { createScope, ElxScopedContainer } from "@elixir/fx";
import { ElxScopedTableContainer } from "@elixir/fx/lib/components/Container/Variations";
import PaginationFooter from "./PaginationFooter";

import {
  ISearchBoxDropdownFilter,
  FilterTypes,
  ITableAction,
  ElxDialog,
  DisplayTypes,
  MatchTypes,
  IContainerAction,
} from "@elixir/components";

import { IDropdownOption } from "@fluentui/react";

import { PageHeader } from "../../../PageHeader/Containers/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { coreSelectors } from "../../../Core/Store/Selectors";
import { RoleLabel } from "../../../PageHeader/Components/RoleLabel/RoleLabel";
import { UserDetails, avaContainers } from "../../../Core/core.data";
import { IColumn } from "@fluentui/react";
import { teamSettingsSelectors } from "../../Store/Selectors";
import { deleteSchedules, getTimezones } from "../../Store/Actions";
import { actions } from "../../Store/Slice";
import {
  ISchedulePanelProps,
  UserScheduleDetails,
  ScheduleDetails,
  scheduleTypes,
  ScheduleDetailsCollection,
  Timezone,
} from "../../Models/teamsettings.data";
import { SchedulesPanel } from "./SchedulesPanel";
import { ScheduleView } from "../../Models/teamsettings.view";
import { ReviewIcmSchedulePanel } from "./ReviewIcmSchedulePanel";
import { createScheduleDisplay } from "./Schedules.utility";
import { renderEmptyView } from "../../../../Common/Core.utility";
import { AvaErrorBoundary } from "../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary";
import { fetchTeamAction } from "../../../Core/Store/Actions";
import "./Schedules.scss";
import { mainTabFix } from "../../../../Common/TabIndexFix";

const CHANNEL_LIST_SEPARATOR = ",";
/**
 * Columns for the schedule table
 */
const columns: IColumn[] = [
  {
    key: "column_Channel",
    name: "Channel",
    fieldName: "channel",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: ScheduleView) => {
      const channelList = item.channel.split(CHANNEL_LIST_SEPARATOR);
      return createScheduleDisplay(channelList[0], channelList.length);
    },
  },
  {
    key: "column_Offering",
    name: "Offering",
    fieldName: "offering",
    minWidth: 400,
    maxWidth: 500,
    isResizable: true,
  },
  {
    key: "column_Role",
    name: "Role",
    fieldName: "role",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "column_User",
    name: "User",
    fieldName: "user",
    minWidth: 475,
    maxWidth: 575,
    isResizable: true,
  },
  {
    key: "column_Alias",
    name: "Alias",
    fieldName: "alias",
    minWidth: 300,
    maxWidth: 400,
    isResizable: true,
  },
  {
    key: "column_Type",
    name: "Type",
    fieldName: "typeName",
    minWidth: 50,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "column_Settings",
    name: "Settings",
    fieldName: "settings",
    minWidth: 650,
    isResizable: true,
  },
];

const schedulesScope = createScope(avaContainers.AVA_SCHEDULE_SETTINGS);

export const Schedules = (): JSX.Element => {
  const dispatch = useDispatch();

  const isPageLoading = useSelector(coreSelectors.getPageLoading);
  const activeTeam = useSelector(coreSelectors.getActiveTeam);
  const schedulesCollection = useSelector(teamSettingsSelectors.getSchedules);
  const user = useSelector(coreSelectors.getCurrentUser);
  const timezones = useSelector(teamSettingsSelectors.getTimezones) || [];

  const [panelProps, setPanelProps] = React.useState<ISchedulePanelProps>({});
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [schedulesToDelete, setSchedulesToDelete] =
    React.useState<UserScheduleDetails>({ schedules: [] });

  const [pageIndex, setPageIndex] = React.useState(0);

  React.useEffect(() => {
    mainTabFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isPageLoading && activeTeam) {
      dispatch(
        fetchTeamAction(
          activeTeam?.teamsTeamId,
          schedulesScope,
          "schedules,roles,channels,offerings,users"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeam, dispatch]);

  React.useEffect(() => {
    dispatch(getTimezones());
  }, [dispatch]);

  const schedules = schedulesCollection.schedules || [];

  const items: ScheduleView[] = getScheduleItems(schedules, timezones);

  const itemsPerPage = 100;
  const paginatedResult =
    items !== undefined && items.length > 0
      ? items.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage)
      : [];
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const roleOptions = getRolesAvailable(items);

  const roleFilter: ISearchBoxDropdownFilter = {
    type: FilterTypes.Dropdown,
    key: "role",
    label: "Role",
    filterProps: {
      multiSelect: true,
      options: roleOptions,
      dropdownWidth: 200,
    },
    displayInline: true,
  };

  const channelOptions = getChannelsAvailable(items);

  const channelFilter: ISearchBoxDropdownFilter = {
    type: FilterTypes.Dropdown,
    key: "channel",
    label: "Channel",
    matchType: MatchTypes.Contains,
    filterProps: {
      multiSelect: true,
      options: channelOptions,
      dropdownWidth: 200,
    },
    displayInline: true,
  };

  const renderOnEdit = (items: ScheduleView[]) => {
    setPanelProps({ schedule: items });
    dispatch(actions.showSchedulesPanel(true));
  };

  const renderOnReview = (item: ScheduleView) => {
    setPanelProps({ schedule: [item] });
    dispatch(actions.showReviewIcmSchedulePanel(true));
  };

  const renderOnDelete = (items: ScheduleView[]) => {
    var schedules = items.map((item) => item.data);
    setSchedulesToDelete({ schedules: schedules });
    setShowDeleteDialog(true);
  };

  const tableActions: ITableAction[] = [
    {
      key: "scheduleEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      disableBulkAction: false,
      onAction: (item: ScheduleView) => renderOnEdit([item]),
      onBulkAction: (items: ScheduleView[]) => renderOnEdit(items),
    },
    {
      key: "scheduleDelete",
      text: "Delete",
      iconProps: { iconName: "Delete", style: { color: "#A80000" } },
      disableBulkAction: false,
      onAction: (item: ScheduleView) => renderOnDelete([item]),
      onBulkAction: (items: ScheduleView[]) => renderOnDelete(items),
    },
    {
      key: "scheduleReview",
      text: "Review IcM schedule",
      iconProps: { iconName: "RedEye" },
      disableBulkAction: true,
      defaultDisplay: DisplayTypes.Disabled,
      onDisplay: (item: ScheduleView) => {
        return item.data.scheduleDetails[0].scheduleType === "IcM"
          ? DisplayTypes.Show
          : DisplayTypes.Disabled;
      },
      onAction: (item: ScheduleView) => renderOnReview(item),
    },
    {
      key: "viewIcm",
      text: "View in IcM",
      iconProps: { iconName: "RedEye" },
      defaultDisplay: DisplayTypes.Disabled,
      disableBulkAction: true,
      onDisplay: (item: ScheduleView) => {
        return item.data.scheduleDetails[0].scheduleType === "IcM"
          ? DisplayTypes.Show
          : DisplayTypes.Disabled;
      },
      onAction: () => renderOnViewInIcm(),
    },
  ];

  const onDeleteSchedules = (
    teamId: string,
    schedulesToDelete: UserScheduleDetails
  ) => {
    dispatch(deleteSchedules(teamId, schedulesToDelete));
  };

  const onDialogDismiss = () => {
    // Clear the roles to be deleted.
    setSchedulesToDelete({ schedules: [] });
    // Dismiss the delete dialog.
    setShowDeleteDialog(false);
  };

  const actionBtnProps: IContainerAction[] = [
    {
      key: "1",
      className: "ms-button-text-padding",
      text: "Add new schedule",
      onClick: () => {
        setPanelProps({});
        dispatch(actions.showSchedulesPanel(true));
      },
    },
    {
      key: "2",
      className: "ms-button-text-padding",
      text: "View documentation",
      href: "https://azsupportdocs.azurewebsites.net/ava/articles/GettingStarted.html#onboarding-steps",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  const displayError = useSelector(coreSelectors.getDisplayError);

  return (
    <ElxScopedContainer
      headerText=""
      onRenderSubHeader={() => {
        return (
          <div className="subHeader">
            Create or edit schedules for your channels. Roles in bold text
            indicated an overriden role.
          </div>
        );
      }}
      actions={displayError ? undefined : actionBtnProps}
      subActions={renderSubActions(user)}
      onRenderHeader={(): JSX.Element => {
        return (
          <PageHeader
            containerScope={schedulesScope}
            include="channels,users,offerings,schedules,roles"
            actions={actionBtnProps}
          />
        );
      }}
    >
      <AvaErrorBoundary>
        <ElxScopedTableContainer
          containerProps={{
            className: "hideHeader",
            headerText: "",
            isLoading: isPageLoading,
          }}
          tableProps={{
            columns: columns,
            items: paginatedResult.sort((user_1, user_2) =>
              user_1.user.localeCompare(user_2.user)
            ),
            stickyHeader: true,
            actions: tableActions,
            onRenderItemColumn: (
              item: ScheduleView,
              index: number | undefined,
              column: IColumn | undefined
            ) => renderRoleColumn(item, index, column),
            ariaLabelForGrid: "Create or Edit schedules",
          }}
          searchBoxProps={{
            filters: [roleFilter, channelFilter],
          }}
          onRenderEmpty={() => renderEmptyView("No schedules found.")}
        >
          <SchedulesPanel {...panelProps}></SchedulesPanel>
          <ReviewIcmSchedulePanel {...panelProps}></ReviewIcmSchedulePanel>
          <ElxDialog
            hidden={!showDeleteDialog}
            dismissable={true}
            dialogContentProps={{
              title: "Delete schedules",
            }}
            primaryButtonProps={{
              text: "Delete",

              onClick: () =>
                onDeleteSchedules(
                  activeTeam?.teamsTeamId ?? "",
                  schedulesToDelete
                ),
            }}
            cancelButtonProps={{
              text: "Cancel",
            }}
            onDismiss={() => onDialogDismiss()}
          >
            <div>Are you sure you want to delete the selected schedules?</div>
          </ElxDialog>
        </ElxScopedTableContainer>
        {!isPageLoading && (
          <PaginationFooter
            itemsPerPage={itemsPerPage}
            paginatedResult={paginatedResult}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            totalPages={totalPages}
          />
        )}
      </AvaErrorBoundary>
    </ElxScopedContainer>
  );
};

function renderSubActions(user?: UserDetails): JSX.Element[] {
  return [<RoleLabel user={user} />];
}

function getRolesAvailable(schedules: ScheduleView[]): IDropdownOption[] {
  const roleSet = new Set(schedules.map((item) => item.role));

  const roles: IDropdownOption[] = Array.from(roleSet).map((role) => ({
    key: role,
    text: role,
  }));

  return roles;
}

function getChannelsAvailable(schedules: ScheduleView[]): IDropdownOption[] {
  const channelSet = new Set<string>();
  schedules.forEach((schedule) => {
    schedule.data.scheduleDetails.forEach((details) =>
      channelSet.add(details.channel?.name || "")
    );
  });

  const channels: IDropdownOption[] = Array.from(channelSet).map((channel) => ({
    key: channel,
    text: channel,
  }));

  return channels;
}

function getScheduleSettingsString(
  schedule: ScheduleDetails,
  timezones: Timezone[]
): string {
  switch (schedule.scheduleType) {
    case "Weekly":
      const timezoneName = timezones.find(
        (z) => z.ianaName === schedule.timeZone
      )?.label;
      return (
        `${schedule.daysOfWeek} ${schedule.start} - ${schedule.end} ${timezoneName}` ||
        ""
      );
    case "AlwaysOn":
      return "Available at all times";
    case "IcM":
      return `${schedule.icmSchedule?.icmTeam?.tenant.name} / ${schedule.icmSchedule?.icmTeam?.name} / ${schedule.icmSchedule?.icmOnCallRole}`;
    default:
      return "";
  }
}

function renderOnViewInIcm() {
  window.open(
    "https://portal.microsofticm.com/imp/v3/oncall/myoncall",
    "_blank"
  );
}

function renderRoleColumn(
  item: ScheduleView,
  index: number | undefined,
  column: IColumn | undefined
): any {
  const fieldContent = item[column?.fieldName as keyof ScheduleView] as string;

  if (column?.name === "Role" && item.hasRoleOverride) {
    return (
      <div>
        <b>{fieldContent}</b>
      </div>
    );
  } else {
    return <div>{fieldContent}</div>;
  }
}

function sortLabels(items: string[], sortOrder: string[]): any {
  var sortingMap = new Map<string, number>();
  sortOrder.forEach((entry, index) => {
    sortingMap.set(entry, index);
  });
  return items.sort(
    (a, b) => (sortingMap.get(a) || 0) - (sortingMap.get(b) || 0)
  );
}

/**
 * Get all the schedules to display.
 * added all the schedules under a use + schedule type grouping
 * @param schedules
 */
function getScheduleItems(
  schedules: ScheduleDetailsCollection[],
  timezones: Timezone[]
): ScheduleView[] {
  const groupedItems: { [key: string]: ScheduleView } = {};

  schedules.forEach((schedule) => {
    if (!schedule){ //null check
      return;
    }

    const availableChannelsSet = new Set<string>();
    const availableOfferingsSet = new Set<string>();
    let settings: string[] = [];

    schedule.scheduleDetails.forEach((details) => {
      availableChannelsSet.add(details.channel?.name || "");
      availableOfferingsSet.add(details.offering?.name || "");
      settings.push(getScheduleSettingsString(details, timezones));
    });

    const availableChannelsList = Array.from(availableChannelsSet).sort(
      (channel_1, channel_2) => channel_1.localeCompare(channel_2)
    );

    const channelDisplay = availableChannelsList.join(CHANNEL_LIST_SEPARATOR);

    const availableOfferingsList = Array.from(availableOfferingsSet);
    const offeringDisplay = sortLabels(availableOfferingsList, [
      "Chat",
      "ARR",
      "Premier",
      "BC",
      "Community",
      "No ID",
    ]).join(", ");
    
    const user = schedule.user.name;
    const userRole = schedule.scheduleDetails[0].role;

    const key = user + "_" + schedule.scheduleDetails[0].scheduleType;

    if (!groupedItems[key]) {
      groupedItems[key] = {
        channel: channelDisplay,
        offering: offeringDisplay,
        role: userRole?.name || schedule.user.role.name,
        user: user,
        alias: schedule.user.alias,
        settings: settings.join("; "),
        id: key,
        hasRoleOverride: userRole ? true : false,
        typeName:
          scheduleTypes.find(
            (o) => o.key === schedule.scheduleDetails[0].scheduleType
          )?.label ?? "",
        data: { ...schedule, scheduleDetails: [...schedule.scheduleDetails] },
      };
    } else {
      groupedItems[key].offering += ", " + offeringDisplay;
      groupedItems[key].settings += "; " + settings.join("; ");
      groupedItems[key].data.scheduleDetails.push(...schedule.scheduleDetails);
    }
  });

  return Object.values(groupedItems);
}

