import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { coreSelectors } from '../../../Core/Store/Selectors';
import { createScope } from '@elixir/fx';
import { ElxScopedTableContainer } from '@elixir/fx/lib/components/Container/Variations';
import { ElxLink, IElxColumn, IElxContainerProps } from '@elixir/components';
import { IColumn, SelectionMode } from '@fluentui/react';
import { CaseDataDetails, avaContainers } from '../../../Core/core.data';
import { fetchTeamCasesAction } from '../../../Core/Store/Actions';
import { PageHeader } from '../../../PageHeader/Containers/PageHeader';
import { renderAutoRefreshToggleSubActions } from '../../../PageHeader/Components/AutoRefreshToggle/AutoRefreshToggle';
import { 
    getCasesTableActions, 
    getChannelFilter, 
    getStatusFilter, 
    getSeverityFilter, 
    getOfferingFilter,
    getCaseStatus,
    convertOffering,
    getTimeWaitedForHelp,
    CASE_REFRESH_WAIT_TIME,
    getLifeAndSafetyString,
    getTimeDifferenceInMinutes,
    sortCaseNumber,
    sortWaitTime,
} from '../../../../Common/Case.utility';
import {
    getTruncatedTime,
    renderEmptyView,
} from '../../../../Common/Core.utility';
import { AvaErrorBoundary } from '../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary';
import { mainTabFix } from '../../../../Common/TabIndexFix';

/**
 * Team cases page table.
 */
export interface TeamCasesTable {
    caseNumber: JSX.Element,
    teamChannel: string,
    caseTitle: string,
    created: string,
    status: string,
    expiredTimers: number,
    lifeAndSafety: string,
    waitTime: string,
    severity: string,
    offering: string,
    teamsUrl: string,
    raveUrl?: string,
    serviceDeskUrl?: string,
    ascUrl?: string
}

/**
 * Columns for the team cases table
 */
const columns: IElxColumn[] = [
    {
        key: 'column_CaseNumber',
        name: 'Case Number',
        fieldName: 'caseNumber',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        sortBy: (column: IElxColumn) => {
            return (a: any, b: any) => {
                return sortCaseNumber(column, a, b);
            };
        }
    },
    {
        key: 'column_TeamChannel',
        name: 'Channel',
        fieldName: 'teamChannel',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
    },
    {
        key: 'column_CaseTitle',
        name: 'Case Title',
        fieldName: 'caseTitle',
        minWidth: 100,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: 'column_Created',
        name: 'Created(UTC)',
        fieldName: 'created',
        minWidth: 100,
        maxWidth: 110,
        isResizable: true
    },
    {
        key: 'column_Status',
        name: 'Status',
        fieldName: 'status',
        minWidth: 60,
        maxWidth: 120,
        isResizable: true
    },
    {
        key: 'column_ExpiredTimers',
        name: 'Expired timers',
        fieldName: 'expiredTimers',
        minWidth: 110,
        maxWidth: 100,
        isResizable: true
    },
    {
        key: 'column_LifeAndSafety',
        name: 'Life & Safety',
        fieldName: 'lifeAndSafety',
        minWidth: 100,
        maxWidth: 160,
        isResizable: true
    },
    {
        key: 'column_WaitTime',
        name: 'Wait Time',
        fieldName: 'waitTime',
        minWidth: 70,
        maxWidth: 80,
        isResizable: true,
        sortBy: (column: IElxColumn) => {
            return (a: any, b: any) => {
                return sortWaitTime(column, a, b);
            };
        }
    },
    {
        key: 'column_Severity',
        name: 'Severity',
        fieldName: 'severity',
        minWidth: 60,
        maxWidth: 80,
        isResizable: true
    },
    {
        key: 'column_Offering',
        name: 'Offering',
        fieldName: 'offering',
        minWidth: 120,
        maxWidth: 150,
        isResizable: true
    }
];

const scope = createScope(avaContainers.AVA_TEAM_CASES);

/**
 * This container is the Team Cases on the portal
 */
export const TeamCases = (): JSX.Element => {
    const dispatch = useDispatch();

    const isPageLoading = useSelector(coreSelectors.getPageLoading);
    const teamCasesSelector = useSelector(coreSelectors.getTeamCases);
    const teamCases = teamCasesSelector || [];
    const items = getTeamCasesTable(teamCases);
    const activeTeam = useSelector(coreSelectors.getActiveTeam);
    const activeTeamId = activeTeam?.teamsTeamId;
    const autoRefreshEnabled = useSelector(coreSelectors.getAutoRefreshEnabled) || false;
    
	React.useEffect(() => {
		mainTabFix();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
	
    React.useEffect(() => {
        if (activeTeamId) {
            dispatch(fetchTeamCasesAction(activeTeamId, scope));
        }
    }, [activeTeamId, dispatch]);
    
    // Auto-refresh every 30secs to fetch team cases 
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!isPageLoading && autoRefreshEnabled && activeTeamId) {
                dispatch(fetchTeamCasesAction(activeTeamId, scope));
            }
        }, CASE_REFRESH_WAIT_TIME);

        return () => clearInterval(interval)
    });

    return (
        <AvaErrorBoundary>
        <ElxScopedTableContainer
          containerProps={{
            headerText: 'Team cases',
            subHeaderText: 'All cases from the last 30 days for the current team',
            isLoading: isPageLoading,
            subActions: renderAutoRefreshToggleSubActions(),
            onRenderHeader: (props: IElxContainerProps) => { return <PageHeader containerScope={scope} /> }
          }}
          tableProps={{
            columns: columns,
            items: items.sort((a, b) => (getTime(b.created) - getTime(a.created))),
            stickyHeader: true,
            actions: getCasesTableActions(),
            selectionMode: items.length > 0 ? SelectionMode.single : SelectionMode.none,
            ariaLabelForGrid: "Cases from the last 30 days"
          }}
          searchBoxProps={{
            filters:[
              getChannelFilter(teamCases), 
              getStatusFilter(teamCases),
              getSeverityFilter(teamCases),
              getOfferingFilter(teamCases),
            ],
            maxInlineFilters: 4
          }}
          onRenderEmpty={() => renderEmptyView("No team cases found.")}        
        >
        </ElxScopedTableContainer>
        </AvaErrorBoundary>
    );
}

function getTime(dateValue: string) {
    const date = new Date(dateValue);
    return date != null ? date.getTime() : 0;
}

/**
 * Method to map team cases data to team cases table.
 * @param teamCases Team cases data.
 */
function getTeamCasesTable(teamCases: CaseDataDetails[]): TeamCasesTable[] {
    var result: TeamCasesTable[] = [];
    if (teamCases) {
        result = teamCases
            .map(c =>({
                caseNumber: <ElxLink href={c.linkToCase} target='_blank'>{c.caseNumber}</ElxLink>,
                teamChannel: c.channelName,
                caseTitle: c.title,
                created: getTruncatedTime(c.timeCreated),
                status: getCaseStatus(c),
                expiredTimers: c.expiredTimerCount < 0 ? 0 : c.expiredTimerCount,
                lifeAndSafety: getLifeAndSafetyString(c),
                waitTime: getTimeWaitedForHelp(c),
                severity: c.severity,
                offering: convertOffering(c.offering),
                teamsUrl: c.linkToCase,
                raveUrl: c.toolsUrls['rave'],
                serviceDeskUrl: c.toolsUrls['sd'],
                ascUrl: c.toolsUrls['asc'],
                dynamicsUrl: c.toolsUrls['dfM'],
                caseId: c.caseNumber,
                timeWaitedInMinutes: getTimeWaitedForHelp(c, getTimeDifferenceInMinutes)
            }));
    }
    return result;
}
