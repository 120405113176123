import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IColumn } from "@fluentui/react";
import { createScope, ElxScopedContainer } from "@elixir/fx";
import { ElxScopedTableContainer } from "@elixir/fx/lib/components/Container/Variations";

import {
  ITableAction,
  IElxContainerProps,
  MatchTypes,
  FilterTypes,
  ISearchBoxDropdownFilter,
  IContainerAction,
} from "@elixir/components";

import { PageHeader } from "../../../PageHeader/Containers/PageHeader";
import { coreSelectors } from "../../../Core/Store/Selectors";
import { teamSettingsSelectors } from "../../Store/Selectors";
import { actions } from "../../Store/Slice";
import { UserDetails, avaContainers } from "../../../Core/core.data";
import { IUserPanelProps } from "../../Models/teamsettings.data";
import { ModifyUserPanel } from "./ModifyUserPanel/ModifyUserPanel";
import { RoleLabel } from "../../../PageHeader/Components/RoleLabel/RoleLabel";
import { PreviewToggle } from "../../../PageHeader/Components/PreviewToggle/PreviewToggle";
import { renderEmptyView } from "../../../../Common/Core.utility";
import { AvaErrorBoundary } from "../../../Core/Containers/AvaErrorBoundary/AvaErrorBoundary";
import { UsersTableView } from "../../Models/teamsettings.view";
import { fetchTeamAction } from "../../../Core/Store/Actions";
import { mainTabFix } from "../../../../Common/TabIndexFix";

const teamUsersScope = createScope(avaContainers.AVA_USER_SETTINGS);

const columns: IColumn[] = [
  {
    key: "column_Name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "column_Alias",
    name: "Alias",
    fieldName: "alias",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "column_Role",
    name: "Role",
    fieldName: "roleName",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "column_IsAdmin",
    name: "IsAdmin",
    fieldName: "adminStatus",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "column_OrgName",
    name: "Org",
    fieldName: "orgName",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "column_IsOutOfOffice",
    name: "OOF",
    fieldName: "isOutOfOffice",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
];

export const Users = (): JSX.Element => {
  const dispatch = useDispatch();
  const activeTeam = useSelector(coreSelectors.getActiveTeam);
  const isPageLoading = useSelector(coreSelectors.getPageLoading);
  const currentUser = useSelector(coreSelectors.getCurrentUser);
  const [peviousTeamId, setPreviousTeamId] = React.useState(
    activeTeam?.teamsTeamId
  );

  // Get all users and roles for the active team.
  const teamUsers = useSelector(teamSettingsSelectors.getTeamUsers).userDetails;

  React.useEffect(() => {
    mainTabFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Check if the team ID has changed
    if (activeTeam?.teamsTeamId !== peviousTeamId) {
      // Update the current team ID state
      setPreviousTeamId(activeTeam?.teamsTeamId);

      // If the page is not loading, dispatch the fetchTeamAction
      if (!isPageLoading && activeTeam) {
        dispatch(
          fetchTeamAction(activeTeam.teamsTeamId, teamUsersScope, "users,roles")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeam, dispatch]);

  // Constructing dropdowns used for filtering the table
  const roleDropdownOptions = Array.from(
    new Set(teamUsers.map((tu) => tu.role.name))
  ).map((role) => ({
    key: role,
    text: role,
  }));
  const orgTypeDropdownOptions = Array.from(
    new Set(teamUsers.map((tu) => tu.userGroupName))
  ).map((org) => ({
    key: org,
    text: org,
  }));

  // Role filter
  const roleFilter: ISearchBoxDropdownFilter = {
    type: FilterTypes.Dropdown,
    key: "roleName",
    label: "Role",
    matchType: MatchTypes.Exact,
    filterProps: {
      multiSelect: true,
      options: roleDropdownOptions,
      dropdownWidth: 200,
    },
    displayInline: true,
  };

  // Org type filter
  const orgTypeFilter: ISearchBoxDropdownFilter = {
    type: FilterTypes.Dropdown,
    key: "orgName",
    label: "Org",
    matchType: MatchTypes.Exact,
    filterProps: {
      multiSelect: true,
      options: orgTypeDropdownOptions,
      dropdownWidth: 200,
    },
    displayInline: true,
  };

  const [panelProps, setPanelProps] = React.useState({} as IUserPanelProps);

  const tableActions: ITableAction[] = [
    {
      key: "actionEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      disableBulkAction: false,
      onBulkAction: (items) => renderOnEdit(items),
      onAction: (item) => renderOnEdit([item]),
    },
  ];

  const tableItems = Array.from(teamUsers).map((teamUser) => ({
    id: teamUser.userId,
    name: teamUser.name,
    alias: teamUser.alias,
    roleName: teamUser.role.name,
    adminStatus: teamUser.isAdmin ? "Yes" : "No",
    isOutOfOffice: teamUser.isOutOfOffice ? "Yes" : "No",
    orgName: teamUser.userGroupName,
    data: teamUser,
  }));

  const actionBtnProps: IContainerAction[] = [
    {
      key: "1",
      text: "View documentation",
      href: "https://azsupportdocs.azurewebsites.net/ava/articles/GettingStarted.html#onboarding-steps",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  const renderOnEdit = (items: UsersTableView[]) => {
    setPanelProps({
      panelScope: teamUsersScope,
      users: items,
    });
    dispatch(actions.showModifyUsersPanel(true));
  };

  const displayError = useSelector(coreSelectors.getDisplayError);

  return (
    <ElxScopedContainer
      headerText=""
      onRenderSubHeader={() => {
        return <div className="subHeader">View and edit users in the Team</div>;
      }}
      actions={displayError ? undefined : actionBtnProps}
      subActions={renderSubActions(currentUser)}
      onRenderHeader={() => {
        return (
          <PageHeader
            containerScope={teamUsersScope}
            include="users,roles"
            actions={actionBtnProps}
          ></PageHeader>
        );
      }}
    >
      <AvaErrorBoundary>
        <ElxScopedTableContainer
          containerProps={{
            className: "hideHeader",
            isLoading: isPageLoading,
          }}
          tableProps={{
            columns,
            items: tableItems.sort((a: UsersTableView, b: UsersTableView) =>
              a.name > b.name ? 1 : -1
            ),
            stickyHeader: true,
            actions: tableActions,
            ariaLabelForGrid: "View and edit users",
          }}
          searchBoxProps={{
            filters: [roleFilter, orgTypeFilter],
          }}
          onRenderEmpty={() => renderEmptyView("No users found.")}
        >
          <ModifyUserPanel {...panelProps} />
        </ElxScopedTableContainer>
      </AvaErrorBoundary>
    </ElxScopedContainer>
  );
};

/**
 * Render action to display current role of the user.
 * @param user Current user.
 * @returns Label element
 */
function renderSubActions(user?: UserDetails): JSX.Element[] {
  const subActions: JSX.Element[] = [];

  if (user?.isAdmin) {
    subActions.push(<PreviewToggle user={user} />);
  }

  subActions.push(<RoleLabel user={user} />);

  return subActions;
}
